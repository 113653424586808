﻿
export function getElementWidth(element) {
    return element.offsetWidth;
}

export function getElementScrollHeight(element) {
    element.classList.add('element-scroll-measure');
    const scrollHeight = element.scrollHeight - 4;
    element.classList.remove('element-scroll-measure');

    return scrollHeight;
}

export function isOverflowing(child, parent, paddingLeft = 0, paddingRight = 0, paddingTop = 0, paddingBottom = 0) {
    if (!child || !parent) {
        return false;
    }

    const childRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    if (childRect.top < parentRect.top + paddingTop) {
        return true;
    }

    if (childRect.bottom > parentRect.bottom - paddingBottom) {
        return true;
    }

    if (childRect.left < parentRect.left + paddingLeft) {
        return true;
    }

    if (childRect.right > parentRect.right - paddingRight) {
        return true;
    }

    return false;
}

export function scrollToElement(element, behaviour = "smooth", block = "start", inline = "nearest") {
    element.scrollIntoView({ behavior: behaviour, block: block, inline: inline });
}

export function getElementBySelector(selector, startingElement) {
    if (!startingElement) {
        startingElement = document;
    }

    return startingElement.querySelector(selector);
}

export function click(element) {
    if (!element) {
        return;
    }

    element.click();
}

export function focus(element) {
    if (!element) {
        return;
    }

    element.focus();
}

export function focusBySelector(selector, startingElement) {
    if (!startingElement) {
        startingElement = document;
    }

    focus(getElementBySelector(selector, startingElement));
}

export function focusSwitch(element) {
    const currentFocus = getActiveElement();
    console.log(currentFocus);
    focus(element);

    return {
        returnFocus() {
            console.log(currentFocus);
            focus(currentFocus);
        }
    };
}

export function blur(element = document.activeElement) {
    if (!element) {
        return;
    }

    element.blur();
}

export function getActiveElement() {
    return document.activeElement;
}

export const appAssembly = "Web.Client";

/**
  * debounce function
  * use inDebounce to maintain internal reference of timeout to clear
*/
export const debounce = (func, delay) => {
    let inDebounce
    return function () {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() =>
            func.apply(context, args)
            , delay)
    }
}

/**
  * throttle function that catches and triggers last invocation
  * use time to see if there is a last invocation
*/
export const throttle = (func, limit) => {
    let lastFunc
    let lastRan
    return function () {
        const context = this
        const args = arguments
        if (!lastRan) {
            func.apply(context, args)
            lastRan = Date.now()
        } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function () {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args)
                    lastRan = Date.now()
                }
            }, limit - (Date.now() - lastRan))
        }
    }
}

export function findClosestScrollContainer(element) {
    if (!element || element === document.body || element === document.documentElement) {
        return null;
    }

    const style = getComputedStyle(element);

    if (style.overflowY !== 'visible') {
        return element;
    }

    return findClosestScrollContainer(element.parentElement);
}

class AutoScrollContainerHandler {
    constructor(container, marginTop = 50, marginBottom = 50, marginLeft = 50, marginRight = 50) {
        this.scrollContainer = findClosestScrollContainer(container);

        if (!this.scrollContainer) {
            this.scrollContainer = document.documentElement;
        }

        this.onDrag = this.onDrag.bind(this);
        this.beginAnimationLoop = this.beginAnimationLoop.bind(this);
        this.endAnimationLoop = this.endAnimationLoop.bind(this);
        this.animationLoop = this.animationLoop.bind(this);
        this.throttleWrapper = throttle(this.onDrag, 30);

        this.scrollContainer.addEventListener('drag', this.throttleWrapper);

        this.active = false;
        this.margin = {
            top: marginTop,
            left: marginLeft,
            bottom: marginBottom,
            right: marginRight
        };
    }

    dispose() {
        this.scrollContainer.removeEventListener('drag', this.throttleWrapper);
        this.scrollContainer = null;
        this.endAnimationLoop();
    }

    enable() {
        this.active = true;
    }

    disable() {
        this.active = false;
    }

    onDrag(e) {
        if (!this.active || !this.scrollContainer) {
            return;
        }

        const intersection = {
            left: e.clientX < this.margin.left,
            right: e.clientX > (this.scrollContainer.clientWidth - this.margin.right),
            top: e.clientY < this.margin.top,
            bottom: e.clientY > (this.scrollContainer.clientHeight - this.margin.bottom),
            mouse: e,
        };

        if (!(intersection.left || intersection.right || intersection.top || intersection.bottom)) {
            this.endAnimationLoop();
            return;
        }

        this.currentIntersection = intersection;
        this.beginAnimationLoop();
    }

    beginAnimationLoop() {
        if (!this.frame) {
            this.frame = requestAnimationFrame(this.animationLoop);
        }
    }

    endAnimationLoop() {
        if (this.frame) {
            cancelAnimationFrame(this.frame);
            this.frame = null;
        }
    }

    animationLoop() {
        if (!this.currentIntersection || !this.active || !this.scrollContainer) {
            this.endAnimationLoop();
            return;
        }

        let scrollFactorX = this.scrollContainer.scrollLeft;
        let scrollFactorY = this.scrollContainer.scrollTop;
        const speed = 30;

        if (this.currentIntersection.left) {
            scrollFactorX += ((this.margin.left - this.currentIntersection.mouse.clientX) / this.margin.left) * -speed;
        } else if (this.currentIntersection.right) {
            scrollFactorX += ((this.currentIntersection.mouse.clientX - (this.scrollContainer.clientWidth - this.margin.right)) / this.margin.right) * speed;
        }

        if (this.currentIntersection.top) {
            scrollFactorY += ((this.margin.top - this.currentIntersection.mouse.clientY) / this.margin.top) * -speed;
        } else if (this.currentIntersection.bottom) {
            scrollFactorY += ((this.currentIntersection.mouse.clientY - (this.scrollContainer.clientHeight - this.margin.bottom)) / this.margin.bottom) * speed;
        }

        scrollFactorX = Math.max(0, Math.min(this.scrollContainer.scrollWidth, scrollFactorX));
        scrollFactorY = Math.max(0, Math.min(this.scrollContainer.scrollHeight, scrollFactorY));

        if (scrollFactorX !== this.scrollContainer.scrollLeft
            || scrollFactorY !== this.scrollContainer.scrollTop) {
            this.scrollContainer.scrollLeft = scrollFactorX;
            this.scrollContainer.scrollTop = scrollFactorY;

            this.frame = null;
            this.beginAnimationLoop();
        }
        else {
            this.endAnimationLoop();
        }
    }
}

export function createAutoScrollContainerHandler(container, marginTop, marginBottom, marginLeft, marginRight) {
    return new AutoScrollContainerHandler(container, marginTop, marginBottom, marginLeft, marginRight);
}

class OnFocusOutsideHandler {
    constructor(target, callback) {
        this.target = target;
        this.callback = callback;
        this.onEvent = this.onEvent.bind(this);

        this.target.addEventListener('focusout', this.onEvent);
    }

    dispose() {
        this.target.removeEventListener('focusout', this.onEvent);
        this.target = null;
        this.callback = null;
    }

    onEvent(e) {
        if (e.relatedTarget && this.target.contains(e.relatedTarget)) {
            return;
        }

        // Defer execution as onfocusout can occur during a blazor renderbatch, in which case interop is locked
        // resulting in an "assertion failed: heap locked" error
        // https://github.com/dotnet/aspnetcore/issues/26809
        setTimeout(async function (callback) { await callback.invokeMethodAsync("InvokeAsync", {}); }, 0, this.callback);
    }
}

export function createOnFocusOutsideEventHandler(target, callback) {
    return new OnFocusOutsideHandler(target, callback);
}

class EventHandler {
    constructor(event, target, mode, callback, rate, eventOptions) {
        this.eventName = event;
        this.target = target;
        this.mode = mode;
        this.callback = callback;
        this.eventOptions = eventOptions;

        this.eventArgsParseMap = {
            "scroll": (e) => { return e; },
            "drag": (e) => this.parseDragEvent(e),
            "dragend": (e) => this.parseDragEvent(e),
            "dragenter": (e) => this.parseDragEvent(e),
            "dragleave": (e) => this.parseDragEvent(e),
            "dragover": (e) => this.parseDragEvent(e),
            "dragstart": (e) => this.parseDragEvent(e),
            "input": (e) => this.parseChangeEvent(e),
            "change": (e) => this.parseChangeEvent(e),
        }
        this.timeBasedInputs = [
            'date',
            'datetime-local',
            'month',
            'time',
            'week',
        ];

        this.onEvent = this.onEvent.bind(this);
        this.throttleWrapper = throttle(this.onEvent, rate);
        this.debounceWrapper = debounce(this.onEvent, rate);

        if (this.mode === 0) {
            // Normal
            this.target.addEventListener(this.eventName, this.onEvent, this.eventOptions);

        } else if (this.mode === 1) {
            // Throttle
            this.target.addEventListener(this.eventName, this.throttleWrapper, this.eventOptions);

        } else if (this.mode === 2) {
            //Debounce
            this.target.addEventListener(this.eventName, this.debounceWrapper, this.eventOptions);
        }

        this.ready = true;
    }

    dispose() {
        if(this.mode === 0) {
            // Normal
            this.target.removeEventListener(this.eventName, this.onEvent, this.eventOptions);

        } else if (this.mode === 1) {
            // Throttle
            this.target.removeEventListener(this.eventName, this.throttleWrapper, this.eventOptions);

        } else if (this.mode === 2) {
            //Debounce
            this.target.removeEventListener(this.eventName, this.debounceWrapper, this.eventOptions);
        }

        this.target = null;
        this.callback = null;
        this.ready = false;
    }

    onEvent(e) {
        const parsedArgs = this.eventArgsParseMap[this.eventName](e);
        this.callback.invokeMethodAsync("InvokeAsync", parsedArgs);
    }

    parseMouseEvent(event) {
        return {
            detail: event.detail,
            screenX: event.screenX,
            screenY: event.screenY,
            clientX: event.clientX,
            clientY: event.clientY,
            offsetX: event.offsetX,
            offsetY: event.offsetY,
            button: event.button,
            buttons: event.buttons,
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
            altKey: event.altKey,
            metaKey: event.metaKey,
        };
    }

    parseDragEvent(event) {
        return {
            ...this.parseMouseEvent(event),
            dataTransfer: event.dataTransfer ? {
                dropEffect: event.dataTransfer.dropEffect,
                effectAllowed: event.dataTransfer.effectAllowed,
                files: Array.from(event.dataTransfer.files).map(f => f.name),
                items: Array.from(event.dataTransfer.items).map(i => ({ kind: i.kind, type: i.type })),
                types: event.dataTransfer.types,
            } : null,
        };
    }

    parseChangeEvent(event) {
        const element = event.target;
        if (this.isTimeBasedInput(element)) {
            const normalizedValue = this.normalizeTimeBasedValue(element);
            return { value: normalizedValue };
        } else {
            const targetIsCheckbox = this.isCheckbox(element);
            const newValue = targetIsCheckbox ? !!element['checked'] : element['value'];
            return { value: newValue };
        }
    }

    isCheckbox(element) {
        return !!element && element.tagName === 'INPUT' && element.getAttribute('type') === 'checkbox';
    }

    isTimeBasedInput(element) {
        return this.timeBasedInputs.indexOf(element.getAttribute('type')) !== -1;
    }

    normalizeTimeBasedValue(element) {
        const value = element.value;
        const type = element.type;
        switch (type) {
            case 'date':
            case 'datetime-local':
            case 'month':
                return value;
            case 'time':
                return value.length === 5 ? value + ':00' : value; // Convert hh:mm to hh:mm:00
            case 'week':
                // For now we are not going to normalize input type week as it is not trivial
                return value;
        }

        throw new Error(`Invalid element type '${type}'.`);
    }
}

export async function saveFile(filename, contentType, stream) {
    const data = await stream.arrayBuffer();
    const file = new File([data], filename, { type: contentType });
    const exportUrl = URL.createObjectURL(file);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = exportUrl;
    a.download = filename;
    a.target = "_self";
    a.click();

    URL.revokeObjectURL(exportUrl);
    document.body.removeChild(a);
}

export async function saveFileFromUrl(filename, url) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.target = "_blank";
    a.click();

    document.body.removeChild(a);
}

export function createEventHandler(event, target, mode, callback, rate, eventOptions) {
    return new EventHandler(event, target, mode, callback, rate, eventOptions);
}

export function printElement(element) {
    try {
        if (!element) {
            return;
        }

        if (element.contentWindow) {
            element.contentWindow.print();
            return;
        }

        element.print();
    } catch (_) { }
}